import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Customer } from "@/config/Modules";
import { getIsSaving } from "@/store/crud/functions/getters";

const CustomerModule = namespace(Customer.store);

@Component({})
export class CustomerStoreMixin extends Vue {
  /**
   * Actions
   */
  @CustomerModule.Action("create")
  protected createCustomerItemAction: any;

  @CustomerModule.Action("findOne")
  protected findOneCustomerItemAction: any;

  @CustomerModule.Action("update")
  protected updateCustomerItemAction: any;

  @CustomerModule.Action("findAll")
  protected findAllCustomerItemsAction: any;

  @CustomerModule.Action("resetSave")
  protected resetSaveCustomerAction: any;

  @CustomerModule.Action("resetList")
  protected resetListCustomerAction: any;

  @CustomerModule.Action("del")
  protected delCustomerItemAction: any;

  @CustomerModule.Action("findAllSubresource")
  protected findAllSubresourceCustomerItemsAction: any;

  @CustomerModule.Action("setDataList")
  protected setDataListCustomerAction: any;

  @CustomerModule.Action("upload")
  protected uploadCustomerAction: any;

  /**
   * Getters
   */
  @CustomerModule.Getter("getError")
  protected getCustomerError: any;

  @CustomerModule.Getter("getIsLoading")
  protected getCustomerIsLoading: any;

  @CustomerModule.Getter("getIsSaving")
  protected getIsCustomerSaving: any;

  @CustomerModule.Getter("getDataItem")
  protected getCustomerItem: any;

  @CustomerModule.Getter("getSuccess")
  protected getCustomerSuccess: any;

  @CustomerModule.Getter("getDataList")
  protected getCustomerList: any;

  @CustomerModule.Getter("getTotal")
  protected getCustomerTotal: any;

  @CustomerModule.Getter("getDeletedSuccess")
  protected getCustomerDeletedSuccess: any;

  /**
   * Mutations
   */
  @CustomerModule.Mutation("IS_LOADING")
  protected setCustomerLoading: any;

  @CustomerModule.Mutation("SET_ERROR")
  protected setCustomerError: any;

  @CustomerModule.Mutation("SET_SUCCESS")
  protected setCustomerSuccess: any;

  @CustomerModule.Mutation("SET_DATA_ITEM")
  protected setCustomerItem: any;

  @CustomerModule.Mutation("SET_DATA_LIST")
  protected setCustomerList: any;

  @CustomerModule.Mutation("SET_TOTAL")
  protected setCustomerTotal: any;

  @CustomerModule.Mutation("SET_DELETED_SUCCESS")
  protected setCustomerDeletedSuccess: any;

  // Additional...
  @CustomerModule.Action("loadCustomerDashboardData")
  protected loadCustomerDashboardData: any;

  @CustomerModule.Getter("getDashboard")
  protected getCustomerDashboard: any;

  @CustomerModule.Mutation("SET_DASHBOARD")
  protected setDashboard: any;
}
