
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { CustomerStoreMixin } from "@/mixins/Stores/CustomerStoreMixin";

@Component({
  components: {},
})
export default class CustomerSidebar extends mixins(CustomerStoreMixin) {
  protected get customerAsideRoutes(): any {
    return this.$router.getRoutes().filter((route: any) => {
      return (
        route.meta?.show_in_aside &&
        route.meta.show_in_aside.aside === "customer"
      );
    });
  }
}
