
import { Component, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { Customer } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { CustomerStoreMixin } from "@/mixins/Stores/CustomerStoreMixin";
import CustomerSidebar from "@/components/modules/customer/CustomerSidebar.vue";
import { AppStoreMixin } from "@/mixins/Stores/AppStoreMixin";

@Component({
  components: { CustomerSidebar, DefaultLayout },
})
export default class CustomerView extends mixins(
  AppStoreMixin,
  CustomerStoreMixin
) {
  @Watch("$route", { immediate: true, deep: true })
  protected onRouteChanged(route: any): void {
    if (route?.params.id) {
      this.findOneCustomerItemAction({
        resource: Customer.resource,
        id: String(route.params.id),
      }).then((response: any) => {
        this.setCurrentCustomer(response.data);
      });
    }
  }
}
